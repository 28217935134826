import { loadVGSCollect } from "@vgs/collect-js";
import { useEffect, useState } from "react";

export const useVGSCardForm = ({
  vaultId,
  env,
  accNoId,
  expId,
  onLoadFail,
  run,
}) => {
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(null);
  const [cardNoValid, setCardNoValid] = useState(false);
  const [expValid, setExpValid] = useState(false);

  useEffect(() => {
    if (run) {
      (async () => {
        try {
          const collect = await loadVGSCollect({
            vaultId, // required
            environment: env,
            // version: '<x.x.x>'
          });

          const form = collect.init();
          setForm(form);
        } catch (e) {
          onLoadFail(e);
          // script was not loaded
        }
      })();
    }
  }, [run]);

  useEffect(() => {
    if (form) {
      const cardNo = form.field(`#${accNoId}`, {
        type: "card-number",
        name: "disbursementNumber",
        validations: ["required", "validCardNumber"],
        showCardIcon: true,
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "1111 1111 1111 1111",
        addCardBrands: [
          {
            type: "paymentcard",
            pattern: /^\d/,
            format: /(\d{1,4})/g,
            length: [15, 16, 17, 18, 19],
            cvcLength: [3, 4],
            luhn: false,
          },
        ],
      });

      const now = new Date();
      const cardExpPlaceholder = `${now.getMonth() + 1} / ${now.getFullYear()}`;
      const cardExp = form.field(`#${expId}`, {
        type: "card-expiration-date",
        name: "card_expirationDate",
        placeholder: cardExpPlaceholder,
        validations: ["validCardExpirationDate"],
        successColor: "#4F8A10",
        errorColor: "#D8000C",
      });

      Promise.all([cardNo, cardExp]).then(() => {
        cardNo.on("update", (e) => setCardNoValid(e.isValid));
        cardExp.on("update", (e) => setExpValid(e.isValid));
        const interval = setInterval(() => {
          if (
            cardExp.loadingState == "loaded" &&
            cardNo.loadingState == "loaded"
          ) {
            setLoading(false);
            clearInterval(interval);
          }
        }, 1000);
      });
    }
  }, [form]);

  const onSubmit = (cb) => {
    if (form) {
      form.submit(
        "/post",
        {},
        (status, data) => {
          if (cb) cb(null, status, data);
        },
        (errors) => {
          if (cb) cb(errors);
        }
      );
    }
  };

  return [form, onSubmit, loading, cardNoValid && expValid];
};
